import { Box } from '@mui/material';
import warnerLogoImage from '../img/warner_logo.svg';
import React from 'react';

export const MobileContactBox = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      sx={{ gridGap: '32px' }}
      marginTop='200px'
    >
      <Box
        sx={{
          textAlign: 'center',
          justifySelf: 'flex-start',
          fontSize: '14px',
          '-webkit-text-stroke': '0.7px black',
          lineHeight: 1.1,
          fontWeight: 900,
        }}
      >
        Kontakt:
        <br />
        Mariusz Maurycy MGMT
        <br /> mariusz.maurycy@gmail.com
      </Box>
      <Box display='flex' justifyContent='center'>
        <img src={warnerLogoImage} style={{ fill: 'white', maxHeight: '20px' }} />
      </Box>
    </Box>
  );
};
