import { Box } from '@mui/material';
import React from 'react';
import { Tracklist } from './Tracklist';
import { ProductGrid } from './ProductGrid';

export const ContentContainer = () => {
  return (
    <Box display='flex' flex={6} width='100%' justifyContent='space-between'>
      <Tracklist />
      <ProductGrid />
    </Box>
  );
};
