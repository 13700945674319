import { mdiFacebook, mdiInstagram, mdiSpotify, mdiYoutube } from '@mdi/js';
import Box from '@mui/material/Box';

import { MobileSocialMediaIcon } from './MobileSocialMediaIcon';
import { FB_LINK, IG_LINK, SPOTIFY_LINK, YT_LINK } from '../common/links';
import { MobileClock } from './MobileClock';
import React from 'react';

export const MobileTopBar = () => {
  return (
    <>
      <Box
        display='flex'
        flex={1}
        sx={{ gridGap: '16px' }}
        width='100vw'
        justifyContent={'space-between'}
        //padding='16px'
        boxSizing='border-box'
      >
        <Box flex={1} display='flex' flexDirection='column' sx={{ gridGap: '16px' }}>
          <Box display='flex' sx={{ gridGap: '16px' }} flex={1} justifyContent='center'>
            <MobileSocialMediaIcon iconPath={mdiSpotify} link={SPOTIFY_LINK} />
            <MobileSocialMediaIcon iconPath={mdiYoutube} link={YT_LINK} />
            <MobileSocialMediaIcon iconPath={mdiInstagram} link={IG_LINK} />
            <MobileSocialMediaIcon iconPath={mdiFacebook} link={FB_LINK} />
          </Box>
          <MobileClock />
        </Box>
      </Box>
    </>
  );
};
