import { Box } from '@mui/material';
import { MobileTracklist } from './MobileTracklist';
import { MobileProductGrid } from './MobileProductGrid';
import React from 'react';

export const MobileContentContainer = () => {
  return (
    <Box
      display='flex'
      flex={6}
      width='100%'
      sx={{
        gridGap: '40px',
      }}
      justifyContent='center'
      flexDirection='column'
      mt='240px'
    >
      <MobileTracklist />
      <Box display='flex' justifyContent='center'>
        <MobileProductGrid />
      </Box>
    </Box>
  );
};
