export const WHITE_TEE_LINK =
  'https://preorder.pl/on/koszulki/t-shirt-meski/saska-butik-t-shirt-bialy?from=landing-page&campaign-id=11';
export const BLACK_TEE_LINK =
  'https://preorder.pl/on/koszulki/t-shirt-meski/saska-butik-t-shirt-bialy-2?from=landing-page&campaign-id=11';
export const SASKA_CD_LINK =
  'https://preorder.pl/muzyka/polska/saska-butik-6?from=landing-page&campaign-id=11';
export const SASKA_CAP_LINK =
  'https://preorder.pl/on/czapki/snapback-2/saska-butik?from=landing-page&campaign-id=11';
export const IG_LINK = 'https://www.instagram.com/mateusz_holak/';
export const SPOTIFY_LINK =
  'https://open.spotify.com/artist/4nOJhl27dVniTozwVZYa4c?si=cPLQaQMsS_KInkv5kNgCGg';
export const YT_LINK = 'https://www.youtube.com/@HolakMateusz/';
export const FB_LINK = 'https://www.facebook.com/holakmateusz';
export const PREORDER_LINK = 'https://preorder.pl/holak';
