import { SocialMediaIcon } from './SocialMediaIcon';
import { mdiFacebook, mdiInstagram, mdiSpotify, mdiYoutube } from '@mdi/js';
import Box from '@mui/material/Box';
import saskaLogo from './img/logo_butik.png';
import { FB_LINK, IG_LINK, SPOTIFY_LINK, YT_LINK } from './common/links';
import React from 'react';

export const TopBar = () => {
  return (
    <Box display='flex' flex={1} width='100%' justifyContent={'space-between'}>
      <Box display='flex' flex={1}>
        <img src={saskaLogo} width={141} height={123} alt='chuj' />
      </Box>
      <Box display='flex' sx={{ gridGap: '16px' }} flex={1} justifyContent='flex-end'>
        <SocialMediaIcon iconPath={mdiSpotify} link={SPOTIFY_LINK} />
        <SocialMediaIcon iconPath={mdiYoutube} link={YT_LINK} />
        <SocialMediaIcon iconPath={mdiInstagram} link={IG_LINK} />
        <SocialMediaIcon iconPath={mdiFacebook} link={FB_LINK} />
      </Box>
    </Box>
  );
};
