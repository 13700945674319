import Box from '@mui/material/Box';
import cdEmoImage from './img/emo_cover.png';
import warnerLogoImage from './img/warner_logo.svg';
import React from 'react';

export const EmoBox = () => {
  return (
    <Box
      display='flex'
      style={{ gridGap: '16px' }}
      minWidth='35vw'
      justifyContent='space-evenly'
      alignItems='center'
    >
      <Box
        sx={{
          textAlign: 'center',
          fontWeight: 900,
          justifySelf: 'flex-start',
          fontSize: '21px',
          '-webkit-text-stroke': '1.2px black',
          letterSpacing: '-2px',
          lineHeight: 1.4,
        }}
      >
        Do każdego produktu
        <br /> z preorderu Saska Butik
        <br /> EMO EP (CD) gratis
      </Box>
      <Box>
        <img src={cdEmoImage} />
      </Box>
      <Box>
        <img src={warnerLogoImage} style={{ fill: 'white', maxHeight: '60px' }} />
      </Box>
    </Box>
  );
};
