import Box from '@mui/material/Box';
import cdEmoImage from '../img/emo_cover.png';
import React from 'react';

export const MobileEmoBox = () => {
  return (
    <Box
      display='flex'
      style={{ gridGap: '16px' }}
      minWidth='35vw'
      justifyContent='space-evenly'
      alignItems='center'
      flexDirection='column'
    >
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        width='100%'
        sx={{
          textAlign: 'center',
          justifySelf: 'flex-start',
          fontSize: '14px',
          '-webkit-text-stroke': '0.7px black',
          lineHeight: 1.1,
          gridGap: '16px',
          fontWeight: 900,
        }}
      >
        Do każdego produktu
        <br /> z preorderu Saska Butik
        <br /> EMO EP (CD) gratis
        <Box>
          <img src={cdEmoImage} width='50px' />
        </Box>
      </Box>
    </Box>
  );
};
