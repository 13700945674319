import { Box } from '@mui/material';
import React from 'react';

export const Contact = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        fontWeight: 900,
        justifySelf: 'flex-start',
        fontSize: '21px',
        '-webkit-text-stroke': '1.2px black',
        letterSpacing: '-2px',
        lineHeight: 1.4,
      }}
    >
      Kontakt:
      <br />
      Mariusz Maurycy MGMT
      <br /> mariusz.maurycy@gmail.com
    </Box>
  );
};
