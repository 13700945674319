import Box from '@mui/material/Box';
import cdImage from '../img/cd_shop.png';
import capImage from '../img/czapa_shop.png';
import tshirtWhiteImage from '../img/tshirt_2_shop.png';
import tshirtBlackImage from '../img/tshirt_shop.png';
import { BLACK_TEE_LINK, SASKA_CAP_LINK, SASKA_CD_LINK, WHITE_TEE_LINK } from '../common/links';
import React from 'react';

export const MobileProductGrid = () => {
  return (
    <Box display='flex' width='50vw' justifyContent='center'>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        justifyContent='center'
        sx={{ gridGap: '16px' }}
      >
        <Box display='flex' flex={1} justifyContent='center' sx={{ gridGap: '16px' }}>
          <Box display='flex' flex={1} justifyContent='center' alignItems='center'>
            <a href={BLACK_TEE_LINK} rel='noopener noreferrer' target='_blank'>
              <img src={tshirtWhiteImage} width='100%' />
            </a>
          </Box>
          <Box display='flex' flex={1} justifyContent='center' alignItems='center'>
            <a href={SASKA_CD_LINK} rel='noopener noreferrer' target='_blank'>
              <img src={tshirtBlackImage} width='100%' />
            </a>
          </Box>
        </Box>
        <Box display='flex' flex={1} sx={{ gridGap: '16px' }}>
          <Box display='flex' flex={1} justifyContent='center' alignItems='center'>
            <a href={SASKA_CAP_LINK} rel='noopener noreferrer' target='_blank'>
              <Box justifySelf='center' justifyContent='center' display='flex'>
                <img src={cdImage} width='70%' />
              </Box>
            </a>
          </Box>
          <Box display='flex' flex={1} justifyContent='center' alignItems='center'>
            <a href={WHITE_TEE_LINK} rel='noopener noreferrer' target='_blank'>
              <img src={capImage} width='100%' />
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
