import Box from '@mui/material/Box';
import { MobileEmoBox } from './MobileEmoBox';
import { MobileContactBox } from './MobileContactBox';
import React from 'react';

export const MobileFooter = () => {
  return (
    <Box
      display='flex'
      flex={1}
      width='100%'
      justifyContent='space-between'
      overflow='wrap'
      mt='16px'
      flexDirection='column'
      sx={{
        gridGap: '32px',
      }}
    >
      <MobileEmoBox />
      <MobileContactBox />
    </Box>
  );
};
