import Box from '@mui/material/Box';
import React from 'react';

export const Tracklist = () => {
  return (
    <Box
      display='flex'
      minWidth='35vw'
      justifyContent='center'
      alignItems='top'
      paddingTop='32px'
      boxSizing='border-box'
    >
      <Box
        sx={{
          textAlign: 'center',
          fontWeight: 900,
          justifySelf: 'flex-start',
          fontSize: '21px',
          '-webkit-text-stroke': '1.2px black',
          letterSpacing: '-2px',
          lineHeight: 1.4,
        }}
      >
        <Box sx={{ color: '#75db6e' }}>TRACKLISTA:</Box>
        <br />
        1. SASKA intro
        <br />
        2. 123
        <br />
        3. Ja zostaję (bd słuchał RHCP)
        <br />
        4. Nigdy Cb nie zapomnę, BARANOVSKI
        <br />
        5. Po prostu pastelowe 2<br />
        6. 509
        <br />
        7. Po prostu fuck yeah
        <br />
        8. Nie wyjeżdżaj stąd
        <br />
        9. 507
        <br />
        10. Wakcje w Polsce, JULIA POŚNIK
        <br />
        11. Warsau, DZIARMA
        <br />
        12. Od nowa, DEMCHENKO
        <br />
        13. Wybacz mi
        <br />
        14. 525
        <br />
        15. 4REAL, BŁAŻEJ KRÓL, ROSALIE
        <br />
        16. Znalazłem pieniądze, TACO HEMINGWAY
        <br />
      </Box>
    </Box>
  );
};
