import { Box } from '@mui/material';
import mobileBackgroundImage from '../img/mobile_bg_img.png';
import { MobileTopBar } from './MobileTopBar';
import { MobileContentContainer } from './MobileContentContainer';
import { MobileFooter } from './MobileFooter';
import logoButikImage from '../img/logo_butik.png';
import React from 'react';

export const MobileMainPage = () => {
  return (
    <Box height='100vh' sx={{ overflowY: 'auto' }}>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        alignItems='center'
        width='100vw'
        justifyContent='center'
        color='white'
        padding='16px'
        boxSizing='border-box'
        sx={{
          backgroundImage: `url(${mobileBackgroundImage})`,
          backgroundSize: '100%',
          overflowY: 'auto',
          //overflowX: 'hidden',
        }}
      >
        <MobileTopBar />
        <Box position='relative' right='100px' top='300px'>
          <img src={logoButikImage} width='70px' />
        </Box>
        <MobileContentContainer />
        <MobileFooter />
      </Box>
    </Box>
  );
};
