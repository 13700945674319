import Box from '@mui/material/Box';
import React from 'react';
import { Clock } from './Clock';
import { Contact } from './Contact';
import { EmoBox } from './EmoBox';

export const Footer = () => {
  return (
    <Box
      display='flex'
      flex={1}
      width='100%'
      justifyContent='space-between'
      overflow='wrap'
      mt='16px'
    >
      <Box
        display='flex'
        style={{ gridGap: '16px' }}
        minWidth='35vw'
        alignItems='center'
        boxSizing='border-box'
        justifyContent='center'
      >
        <Clock />
        <Contact />
      </Box>
      <EmoBox />
    </Box>
  );
};
