import "./App.css";
import v2 from "./1.mov";
import v1 from "./2.mov";
import v3 from "./3.mov";

import p2 from "./3.jpg";
import p1 from "./1.jpg";
import p3 from "./2.jpg";

import mob1 from "./mobile/001.jpg";
import mob2 from "./mobile/002.jpg";
import mob3 from "./mobile/003.jpg";
import mob4 from "./mobile/004.jpg";
import mob5 from "./mobile/005.jpg";
import mob6 from "./mobile/006.jpg";

import reel from "./reel.mov";
import { useEffect, useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import insta from "./insta.png";
import spoti from "./spoti.png";
import music from "./music.png";
import yt from "./yt.png";
import deezer from "./deezer.png";
import { useMediaQuery } from "react-responsive";
import { Preorder } from "./preorder/Preorder";

function Covers() {
  const [coversLeft, setCoversLeft] = useState(0);
  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.event.type !== "touchmove") {
        setCoversLeft(
          (prev) => prev + (eventData.deltaX / 100) * eventData.velocity
        );
      } else {
        setCoversLeft(
          (prev) => prev + (eventData.deltaX / 10) * eventData.velocity
        );
      }
    },
    delta: 10,
    preventDefaultTouchmoveEvent: false,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
  });

  return (
    <div className="main white coversContainer">
      <div className="pane" {...handlers}>
        <div
          className="covers"
          style={{ backgroundPositionX: `${coversLeft}px` }}
        ></div>
      </div>

      <Link to="" class="arrowBack "></Link>
    </div>
  );
}

function MobileBg() {
  const [coversLeft, setCoversLeft] = useState(0);
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      setCoversLeft((prev) => {
        if (eventData.dir === "Left") {
          if (prev <= 0) return 5;
          else return prev - 1;
        } else {
          if (prev >= 5) return 0;
          else return prev + 1;
        }
      });
    },
    delta: 10,
    preventDefaultTouchmoveEvent: false,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
  });
  return (
    <div className="mobile fullScreenHome" {...handlers}>
      <img src={mob1} className={coversLeft === 0 ? "active" : " "} />
      <img src={mob2} className={coversLeft === 1 ? "active" : " "} />
      <img src={mob3} className={coversLeft === 2 ? "active" : " "} />
      <img src={mob4} className={coversLeft === 3 ? "active" : " "} />
      <img src={mob5} className={coversLeft === 4 ? "active" : " "} />
      <img src={mob6} className={coversLeft === 5 ? "active" : " "} />
    </div>
  );
}

function Contact() {
  return (
    <div className="main white">
      <div className="contact">
        booking:
        <br /> Mariusz Maurycy MGMT
        <br />
        <a href="mailto:mariusz.maurycy@gmail.com">mariusz.maurycy@gmail.com</a>
        <br />
        <a href="tel:0048505768069"> +48 505 768 069</a>
        <br />
        <br />
        instagram: <br />
        Fed Anikin <br />
        <a href="mailto:agnieszka.anikin@gmail.com">
          agnieszka.anikin@gmail.com
        </a>
        <br /> <a href="tel:0048694478214">+48 694 478 214</a> <br />
      </div>

      <Link to="" class="arrowBack"></Link>
    </div>
  );
}

function Video() {
  const vidRef = useRef(null);
  const [p, setP] = useState(false);
  const handlePlayVideo = () => {
    if (p) {
      vidRef.current.pause();
      setP(false);
    } else {
      vidRef.current.play();
      setP(true);
    }
  };
  return (
    <div className="main">
      <div className="background" onClick={handlePlayVideo}>
        <video
          className="fullScreen"
          key={reel}
          preload="metadata"
          ref={vidRef}
        >
          <source src={reel} type="video/mp4"></source>
        </video>
      </div>
      <div
        class={`playPause ${p ? "pause" : ""}`}
        onClick={handlePlayVideo}
      ></div>
      <Link to="" class="arrowBack white"></Link>
    </div>
  );
}

function Clock() {
  const [time, setTime] = useState("");

  useEffect(() => {
    const i = setInterval(() => {
      //const date = new Date();
      setTime(new Date().toTimeString().substr(0, 8));
    });
    return () => {
      clearInterval(i);
    };
  }, []);
  return <>{time}</>;
}

function App() {
  const [geo, setGeo] = useState(false);
  useEffect(() => {
    setTimeout(
      () =>
        setGeo(
          window.holocation && window.holocation.city
            ? window.holocation
            : false
        ),
      500
    );
  }, []);

  const date = new Date();

  const [video, setVideo] = useState({ v: v1, p: p1 });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const [social, setSocial] = useState(true);
  const toggleSocial = () => setSocial(!social);
  console.log({ social });
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <div className="main">
            <div className="background ">
              {isBigScreen && (
                <video
                  autoPlay
                  loop
                  muted
                  className="fullScreen desktop"
                  key={video.v}
                  poster={video.p}
                >
                  <source src={video.v} type="video/mp4"></source>
                </video>
              )}
              <MobileBg />
            </div>
            <p className="left">
              <div className="bg">
                I’m a multidisciplinary artist based in Warsaw. I’m mostly
                recognizable as a vocalist, rapper, producer, but also as a
                visual designer and art director in polish music industry. I
                earned a master’s degree in Academy of Fine Arts in Poznań. My
                list of clients is long and starts with Coca Cola, Mc Donald’s,
                Reebok and is followed by many well-known polish artists. When
                it comes to my music you can check it out on Spotify and Tidal
                under Holak.
              </div>
              <br className="desktop" />
              <br className="desktop" />
              <br />
              <span className="desktop">
                <br />© 2021 All Rights reserved.
              </span>
              <span className="mobile bg">
                {geo && (
                  <>
                    <br /> <Clock />
                    <br />
                    {`${geo.city}, ${geo.state}`}
                  </>
                )}
                <br /> {date.getDate()}.{date.getMonth() + 1}
              </span>
              {!isBigScreen && (
                <div
                  className={`burger ${social && "active"}`}
                  onClick={toggleSocial}
                ></div>
              )}
            </p>

            <p className={`right ${social && "active"}`}>
              <div className="desktop">
                Current Local Time
                <br /> <Clock />
                {geo && (
                  <>
                    <br />
                    {`${geo.city}, ${geo.state}`}
                  </>
                )}
                <br /> {date.getDate()}.{date.getMonth() + 1}
                <br />
                <br />
                <br />
              </div>
              {!isBigScreen && (
                <div className="close" onClick={toggleSocial}></div>
              )}
              <div className="social">
                <a
                  href="https://open.spotify.com/artist/4nOJhl27dVniTozwVZYa4c"
                  target="_blank"
                >
                  <img src={spoti} alt="Spotify" />
                </a>
                <a
                  href="https://www.instagram.com/mateusz_holak"
                  target="_blank"
                >
                  <img src={insta} alt="Instagram" />
                </a>
                <a
                  href="https://music.apple.com/pl/artist/holak/1073617678?l=pl"
                  target="_blank"
                >
                  <img src={music} alt="Music" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCHmG5-hrm6Gb3Kyo_KTVaEw"
                  target="_blank"
                >
                  <img src={yt} alt="youtube" />
                </a>

                <a
                  href="https://tidal.com/browse/artist/8463136"
                  target="_blank"
                >
                  <img src={deezer} alt="deezer" />
                </a>
              </div>
            </p>
            <nav>
              <div className="inner">
                <Link to="/covers">Okładki</Link>

                <Link to="/video">Wideo</Link>

                <a href="#" className="disabled">
                  PDF
                </a>
                <div className="break mobile" />
                <a href="https://penga.online/" target="_blank">
                  Png
                </a>
                <Link to="/contact">Kontakt</Link>
                <a href="#" className="disabled">
                  Wystawa
                </a>
              </div>
            </nav>
            <div className="videoNav desktop">
              <div className="inner">
                <div
                  className={video.v === v1 && "active"}
                  onClick={() => setVideo({ v: v1, p: p1 })}
                ></div>
                <div
                  className={video.v === v2 && "active"}
                  onClick={() => setVideo({ v: v2, p: p2 })}
                ></div>
                <div
                  className={video.v === v3 && "active"}
                  onClick={() => setVideo({ v: v3, p: p3 })}
                ></div>
              </div>
            </div>
          </div>
        </Route>
        <Route path="/covers" exact>
          <Covers />
        </Route>
        <Route path="/video" exact>
          <Video />
        </Route>
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path='/preorder' exact>
          <Preorder/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
