import { Box } from '@mui/material';
import { parse, intervalToDuration } from 'date-fns';
import { useState, useEffect } from 'react';
import React from 'react';

export const Clock = () => {
  const premiereDate = parse('26-01-2024 00:00:00', 'dd-MM-yyyy HH:mm:ss', new Date());
  const [time, setTime] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(intervalToDuration({ start: premiereDate, end: new Date() }));
    }, 1000);
    return () => clearInterval(interval);
  }, [premiereDate]);

  return (
    <Box
      sx={{
        textAlign: 'center',
        fontWeight: 900,
        justifySelf: 'flex-start',
        fontSize: '21px',
        '-webkit-text-stroke': '1.2px black',
        letterSpacing: '-2px',
        lineHeight: 1.4,
      }}
    >
      Do końca
      <br />
      preorderu zostało:
      <br />
      {time?.days} dni {time?.hours ?? 0}h {time?.minutes}m {time?.seconds}s
    </Box>
  );
};
