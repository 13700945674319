import { Box } from '@mui/material';
import { parse, intervalToDuration } from 'date-fns';
import { useState, useEffect } from 'react';
import React from 'react';

export const MobileClock = () => {
  const premiereDate = parse('26-01-2024 00:00:00', 'dd-MM-yyyy HH:mm:ss', new Date());
  const [time, setTime] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(intervalToDuration({ start: premiereDate, end: new Date() }));
    }, 1000);
    return () => clearInterval(interval);
  }, [premiereDate]);

  return (
    <Box
      sx={{
        textAlign: 'center',
        justifySelf: 'flex-start',
        fontSize: '14px',
        '-webkit-text-stroke': '0.7px black',
        lineHeight: 1.1,
        fontWeight: 900,
      }}
    >
      Do końca
      <br />
      preorderu zostało:
      <br />
      {time?.days} dni {time?.hours ?? 0} godzin {time?.minutes} minut {time?.seconds} sekund
    </Box>
  );
};
