import { Box } from '@mui/material';
import backgroundImage from './img/bg_img_desktop.png';
import { TopBar } from './TopBar';
import { ContentContainer } from './ContentContainer';
import { Footer } from './Footer';
import React from 'react';

export const MainPage = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      flex={1}
      alignItems='center'
      justifyContent='center'
      height='100vh'
      width='100vw'
      color='white'
      padding='16px'
      boxSizing='border-box'
      overflow='hidden'
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <TopBar />
      <ContentContainer />
      <Footer />
    </Box>
  );
};
