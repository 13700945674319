import { Box } from '@mui/material';
import MdiIcon from '@mdi/react';
import React from 'react';

export const SocialMediaIcon = ({ iconPath, link }) => {
  return (
    <a href={link} rel='noopener noreferrer' target='_blank'>
      <Box
        sx={{
          height: '60px',
          display: 'flex',
          width: '60px',
          borderRadius: '100px',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'black',
          boxShadow: '4px 4px 32px -7px rgba(0, 0, 0, 0.44)',
          cursor: 'pointer',
        }}
      >
        <MdiIcon path={iconPath} size={1.5} />
      </Box>
    </a>
  );
};
